// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/logos/expand-badge.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manager-badge {
  position: relative;
  display: flex;
  align-items: self-end;
  flex: 1 1 auto;
  z-index: 2;
  color: var(--txt-primary);
  height: 48px;
}
.manager-badge__content {
  position: absolute;
  display: flex;
  margin: 24px 0;
}
.manager-badge__photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 5px;
  border: 1px solid var(--bg-border);
}
.manager-badge__photo img {
  max-width: 46px;
  max-height: 46px;
}
.manager-badge__photo-extra {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}
.manager-badge__photo-extra img {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.manager-badge__arrow {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  min-height: 46px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid var(--bg-border);
  border-left: transparent;
  background: var(--bg-primary);
}
.manager-badge__arrow-icon {
  width: 46px;
  min-height: 46px;
  background-color: var(--txt-primary);
  -webkit-mask: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 50% 50%;
          mask: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 50% 50%;
}
.manager-badge__divider {
  border-left: 1px solid var(--bg-border);
  height: 24px;
  min-width: 1px;
  margin: 0 0 0 8px;
}
.manager-badge__information {
  display: flex;
  flex-direction: column;
  height: 46px;
  min-width: 112px;
  max-width: 112px;
}
.manager-badge__info-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--bg-border);
  border-bottom: 1px solid var(--bg-border);
  border-right: 1px solid var(--bg-border);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: var(--bg-primary);
}
.manager-badge__info-block__half-bordered {
  border-right: 0;
  border-radius: 0;
}
.manager-badge__contact {
  display: flex;
  align-items: center;
  justify-content: center;
}
.manager-badge__contact-icon {
  margin: 0 12px 0 16px;
  width: 18px;
  height: 18px;
  background-color: var(--txt-primary);
  -webkit-mask: var(--mask-social);
          mask: var(--mask-social);
}
.manager-badge__contact-text {
  padding-right: 8px;
}
.manager-badge__contact-text a {
  font-size: 14px;
  width: 100%;
  height: 100%;
  color: var(--txt-primary);
  text-decoration-style: dashed;
  text-decoration-color: var(--bg-border);
}
.manager-badge__expanded {
  display: flex;
  justify-content: center;
  align-items: center;
}
.manager-badge__expanded .manager-badge__information {
  max-width: 200px;
}
.manager-badge__contacts {
  display: flex;
  justify-content: center;
  align-items: center;
}
.manager-badge__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  padding-top: 6px;
  padding-bottom: 2px;
  padding-left: 8px;
}
.manager-badge__position {
  font-size: 12px;
  padding-left: 8px;
}
.expand-badge__rotated {
  transform: rotate(180deg);
}
@media (max-width: 768px) {
.manager-badge {
    display: none;
}
}
@media (min-width: 768px) {
.nav--collapsed .manager-badge a {
    font-size: 14px;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
